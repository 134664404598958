<template>
  <div class="container">
    <header-back name='订单统计'></header-back>
    <section class="top44">
      <div class="sta-cen mt16">
        <van-field
          readonly
          name="calendar"
          :value="value"
          label="开始/结束日期"
          label-width='7em'
          placeholder="选择日期区间"
          @click="showCalendar = true"
        >
          <template
            #right-icon
            v-if="value"
          >
            <van-icon
              name="clear"
              color="#c8c9cc"
              @click.stop="onClearable"
            />
          </template>
        </van-field>
        <van-calendar
          type="range"
          :min-date="minDate"
          :color="variables.textColor"
          v-model="showCalendar"
          allow-same-day
          @confirm="onConfirm"
        />
      </div>
      <div class="sta-m mt16">
        <van-cell
          value=""
          label=""
        >
          <div slot="title">
            <div class="van-cell-text">
              <van-image
                width="22"
                height="22"
                :src='require("../../assets/image/person/proint.png")'
              /> &nbsp;&nbsp;总积分值
            </div>
          </div>
          <div slot="right-icon">
            <span class="van-text-color">{{actualPriceSum}}</span>
          </div>
        </van-cell>
        <van-cell
          value=""
          label=""
        >
          <div slot="title">
            <div class="van-cell-text">
              <van-image
                width="22"
                height="22"
                :src='require("../../assets/image/person/money.png")'
              /> &nbsp;&nbsp;总订单金额
            </div>
          </div>
          <div slot="right-icon">
            <span class="van-text-color">{{checkSellPriceSum}}</span>
          </div>
        </van-cell>
        <van-cell
          value=""
          label=""
        >
          <div slot="title">
            <div class="van-cell-text">
              <van-image
                width="22"
                height="22"
                :src='require("../../assets/image/person/order.png")'
              /> &nbsp;&nbsp;总订单数
            </div>
          </div>
          <div slot="right-icon">
            <span class="van-text-color">{{count}}</span>
          </div>
        </van-cell>
      </div>
      <div class="sta-m pb16 ">
        <div
          class="sta-mm mt16"
          v-for="(item,index) in orderList"
          :key='index'
        >
          <van-cell
            value=""
            title="工号"
          >
            <div slot="right-icon">
              <span>{{item.username}}</span>
            </div>
          </van-cell>
          <van-cell
            value=""
            title="名称"
          >
            <div slot="right-icon">
              <span>{{item.nickname}}</span>
            </div>
          </van-cell>
          <van-cell
            value=""
            title="订单数"
          >
            <div slot="right-icon">
              <span>{{item.count}}</span>
            </div>
          </van-cell>
          <van-cell
            value=""
            title="积分值"
          >
            <div slot="right-icon">
              <span class="van-text-color">{{item.actualPriceSum}}</span>
            </div>
          </van-cell>
          <van-cell
            value=""
            title="面值"
          >
            <div slot="right-icon">
              <span class="van-text-red">￥{{item.checkSellPriceSum}}</span>
            </div>
          </van-cell>
        </div>
        <div style="color: rgb(150, 151, 153); font-size: 14px; text-align: center;padding-top:20px">没有更多了</div>
      </div>
    </section>
  </div>
</template>
<script>
import HeaderBack from "../../components/header-back";
import { Toast } from "vant";
import utils from "../../utils/index";
import { request } from "http";
import variables from "@/styles/variables.scss";
import API from "../../api/http";
export default {
  components: { HeaderBack },
  data() {
    return {
      value: "",
      showCalendar: false,
      proint: "234",
      actualPriceSum: "",
      checkSellPriceSum: "",
      count: "",
      vanImg: require("../../assets/image/person/order1.png"),
      orderList: [],
      startDate: "", //开始日期
      endDate: "", //结束日期
      minDate: new Date(2010, 0, 1),
    };
  },
  computed: {
    variables() {
      return variables;
    },
  },
  created() {
    this.setIsDate();
  },
  methods: {
    getInit(data) {
      API.getOrderStatisics(data).then((res) => {
        console.log(res);
        let data = res.data;
        let statistic = data.statistic;
        let statisticList = data.statisticList;
        this.actualPriceSum = statistic.actualPriceSum;
        this.checkSellPriceSum = statistic.checkSellPriceSum;
        this.count = statistic.count;
        this.orderList = statisticList;
      });
    },
    formatDate(date) {
      return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
    },
    onConfirm(date) {
      const [start, end] = date;

      this.showCalendar = false;
      this.startDate = utils.parseTime(start, "{y}-{m}-{d}") + " " + "00:00:00";

      this.endDate = utils.parseTime(end, "{y}-{m}-{d}") + " " + "23:59:59";
      this.value = `${this.formatDate(start)} - ${this.formatDate(end)}`;
      this.getInit({
        startDate: this.startDate,
        endDate: this.endDate,
        page: 1,
        limit: 100,
      });
    },
    onClearable() {
      this.value = "";
      this.startDate = "";
      this.endDate = "";
      this.getInit({ page: 1, limit: 100 });
    },
    setIsDate() {
      let start = new Date();
      let end = new Date();
      this.startDate = utils.parseTime(start, "{y}-{m}-{d}") + " " + "00:00:00";
      this.endDate = utils.parseTime(end, "{y}-{m}-{d}") + " " + "23:59:59";
      this.value = `${this.formatDate(start)} - ${this.formatDate(end)}`;
      this.getInit({
        startDate: this.startDate,
        endDate: this.endDate,
        page: 1,
        limit: 100,
      });
    },
  },
};
</script>
<style lang='scss' scoped>
@import "../../styles/variables.scss";
.container {
  padding: 0 15px;
  box-sizing: border-box;
}
.sta-cen {
  border-radius: 6px;
  overflow: hidden;
}
.van-cell-text {
  color: #595959;
}
.sta-mm {
  .van-cell {
    color: #777;
  }
}
.van-text-color {
  color: $textColor;
}
.van-text-red {
  color: $redColor;
}
</style>
